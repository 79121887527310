<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'App',
  mounted() {
    // const params = this.$route.query;
    // if (params.orderno) {
    //   this.$router.push({path: '/order', query: {orderno: params.orderno}})
    // }
  }
}
</script>

<style>
@import "@/styles/main.css";
@import 'animate.css';

.el-message-box {
  width: fit-content !important;
  min-width: 300px;
}
</style>
