<template>
  <div style="text-align:center">
<!--    <el-button type="primary" @click="generateEXCEL">Download EXCEL 导出EXCEL</el-button>-->
    <el-image v-if="imageShow===true" :src="require('../assets/1.jpg')" @click="imageShow=false" fit="fit"></el-image>
    <div v-else style="height: 100vh;">
      <div style="margin-top: 5px">当前订单共有 <span style="color: red">{{ total }}</span> 张,请滑动鼠标到底部导出PDF
      </div>
      <div style="margin-top: 5px">当前设备为
        <span v-if="limit===80">IOS系统，</span>
        <span v-else>非IOS系统，</span>
        每张订单最多<span style="color: red">{{ limit }}</span> 个商品
      </div>
      <div style="color: red;margin-top: 10px">*请确认图片加载完成后再导出PDF</div>
      <div id="content" ref="pdfContent" style="width: 714px;text-align: center">
        <div style="text-align: left">{{ page }} / {{ total }} 页</div>
        <h1 style="text-align: center">Orden de despacho</h1>
        <table cellpadding="10" style="width: 714px;">
          <thead>
          <tr style="font-size: 14px;text-align: left">
            <th>Cliente:<span style="letter-spacing:1px">{{ rowData.name }}</span></th>
            <th>Cliente-TEL:<span style="letter-spacing:1px">{{ rowData.phone }}</span></th>
            <th>Email:<span style="letter-spacing:1px">{{ rowData.consumer_email }}</span></th>
          </tr>
          <tr style="font-size: 14px;text-align: left">
            <th>RIF:<span style="letter-spacing:1px;">{{ rowData.rif }}</span></th>
            <th colspan="2">DIRECCION:<span style="letter-spacing:1px;width:100%;">{{ rowData.address }}</span></th>
          </tr>
          </thead>
        </table>
        <table border="1" cellpadding="4" cellspacing="0" style="width: 714px;text-align: center">
          <thead>
          <tr style="font-size: 1rem;text-align: center">
            <th style="max-width: 134px;border: solid 1px">Código</th>
            <th style="max-width: 240px;border: solid 1px">Descripción</th>
            <th style="max-width: 60px;border: solid 1px">Fotos</th>
            <th style="max-width: 80px;border: solid 1px">Cant</th>
            <th style="max-width: 100px;border: solid 1px">Precio Und</th>
            <th style="max-width: 100px;border: solid 1px">Total</th>
          </tr>
          </thead>
          <tbody style="font-size: 12px">
          <tr v-for="(item,idex) in row" :key="idex" style="text-align: center;height: 70px">
            <td style="max-width:134px;border: solid 1px">{{ item.unit_no }}</td>
            <td style="max-width: 240px;border: solid 1px"> {{ item.name }}+({{ item.unit }})</td>
            <td style="max-width: 60px;border: solid 1px">
              <el-image style="width: 40px;height: 50px" :src="url + item.url +'?imageMogr2/thumbnail/50x'" lazy> </el-image>
            </td>
            <td style="max-width: 80px;border: solid 1px">{{ item.num }}</td>
            <td style="max-width: 100px;border: solid 1px">{{ item.price }}</td>
            <td style="max-width: 100px;border: solid 1px"> {{ item.price * item.num| numberFixed }}</td>
          </tr>
          </tbody>
        </table>
        <div style="margin-top: 20px" v-if="page===total">
          <div style="text-align: right;font-size: 18px">Total：{{ rowData.amount }}</div>
          <div v-if="rowData.desc" style="font-size: 16px;text-align: left">Comentarios：{{ rowData.desc }}</div>
        </div>
      </div>
      <div style="padding: 20px 0" class="flex-row-jcenter">
        <div>
          <el-tag @click="getList(i+1)" v-for="(one,i) in total" :key="i" :class="{'selected':page===i+1}"
                  style="margin-right: 5px">第 {{ i + 1 }} 页
          </el-tag>
          <div style="margin-top: 5px">当前订单共有 <span style="color: red">{{ total }}</span> 张</div>
          <el-button type="primary" @click="generatePDF">Download PDF 导出第{{ page }}张PDF</el-button>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import {printData} from "@/api";
import table2excel from "../../utils/export";

export default {
  name: 'OrderI',
  data() {
    return {
      row: [],
      url:'https://xby-1323207363.cos.sa-saopaulo.myqcloud.com/',
      page: 1,
      total: 0,
      rowData: {},
      orderno: '',
      info:{},
      image:'../assets/1.jpg',
      imageShow:false,
      limit:600,
      column: [
        {
          title: 'Código',
          key: 'unit_no',
          type: 'text',
          width: 160
        },
        {
          title: 'Descripción',
          key: 'name',
          type: 'text',
          width: 240,
          height: 100
        },
        {
          title: 'Fotos',
          key: 'fximage',
          type: 'image',
          width: 60,
          height: 70
        },
        {
          title: 'Cant',
          key: 'num',
          type: 'text',
          width: 100
        },{
          title: 'Precio Und',
          key: 'price',
          type: 'text',
          width: 100
        },{
          title: 'Total',
          key: 'amount',
          type: 'text',
          width: 100
        }
      ]
    }
  },
  created() {
    //判断是不是微信
    // var u = navigator.userAgent;
    // var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
    // var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
    // let isWeixin=u.indexOf('MicroMessenger') > -1//是否微信
    // let isIPad= u.indexOf('iPad') > -1 //是否iPad
    // //判断是否微信
    // if (isWeixin===true || isIPad===true){
    //   //安卓和ios不同的动作
    //   if (isAndroid){
    //     //打开
    //     open('https://api2.bernice1115.com/wx.php?order_no='+this.orderno)
    //   }
    //   if (isiOS){
    //     this.imageShow=true
    //   }
    // }

  },
  mounted() {
    const params = this.$route.query;
    this.orderno = params.orderno
    this.checkAU()
    // this.init()
  },
  methods: {
    checkAU(){
      var u = navigator.userAgent;
      var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
      var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      let isWeixin=u.indexOf('MicroMessenger') > -1//是否微信
      let isIPad= u.indexOf('iPad') > -1 //是否iPad
      if (!isIPad){
        isIPad= u.indexOf('Macintosh') > -1 //是否iPad
      }
      //判断是否微信
      if (isWeixin===true){
        //安卓和ios不同的动作
        if (isAndroid){
          //打开
          open('https://api2.bernice1115.com/wx.php?order_no='+this.orderno)
          return
        }
        if (isiOS){
          this.imageShow=true
        }
      }else {
        if (isiOS || isIPad){
          this.limit=80
        }
        this.init()
      }
    },
    init() {
      printData({order_no: this.orderno, page: this.page,limit:this.limit}).then(res => {
        if (res.code === 1) {
          this.rowData = res.data.order
          this.row = res.data.detail.data
          this.total = res.data.detail.last_page
        }
      })
      console.log(this.rowData)
    },
    getList(e) {
      this.page = e
      this.init()
    },
    generatePDF() {
      var u = navigator.userAgent;
      var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
      var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      let isWeixin=u.indexOf('MicroMessenger') > -1//是否微信
      let isIPad= u.indexOf('iPad') > -1 //是否iPad
      //判断是否微信
      if (isWeixin===true || isIPad===true){
        //安卓和ios不同的动作
        if (isAndroid){
          //打开
          open('https://api2.bernice1115.com/wx.php?order_no='+this.orderno)
          return
        }
        if (isiOS){
          this.imageShow=true
          return
        }
      }else {
        this.$nextTick(() => {
          const title = this.rowData.name + '(NO' + this.page + ')'
          this.htmlToPdf('content', title, this.row.length)
        })
      }

    },
    channel() {
      this.$emit('showEvent')
    },
    generateEXCEL(){
      let column = this.column;
      let datas = this.Format(this.row);
      table2excel(column, datas, '导出的表格', '', '');    //生成Excel表格，自动下载
    },
    Format(tableData) {
      let data = [];
      tableData.forEach((item) => {
        let one={
          unit_no:item.unit_no,
          name:item.name + '('+item.unit +')',
          fximage:this.url + item.url+'?imageMogr2/thumbnail/50x',
          num:item.num,
          price:item.price,
          amount:(item.price * item.num).toFixed(2),
        }
        data.push(one)
      })
      return data
    }

  }
}
</script>

<style lang="scss" scoped>
.selected {
  color: red;
}


</style>
